import React from "react"
import AOS from "aos"

import "aos/dist/aos.css"
import "../Companies/Companies.scss"
import "../../styles/_global.scss"

import gerdau from "../../images/group/gerdau.svg"
import elfa from "../../images/group/elfa.svg"
import leroy_merlin from "../../images/group/leroy_merlin.svg"
import vitrium from "../../images/group/vitrium.svg"
import armazemParaiba from "../../images/group/armazemParaiba.svg"
import digna from "../../images/group/digna.svg"
import grupoNeves from "../../images/group/grupoNeves.svg"
import carrefour from "../../images/group/carrefour.svg"
import alpargatas from "../../images/group/alpargatas.svg"
import guaraves from "../../images/group/guaraves.svg"
import hands from "../../images/hands.svg"
// import handsLarge from "../../../images/hands-large.svg"
// import handsSmallDown from "../../../images/handsSmallDown.svg"

const Companies = ({ noBackground = false }) => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
    })
  })
  return (
    <section
      id="companies"
      className={`${noBackground ? "no-background" : ""}`}
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <div className="center">
        {noBackground ? (
          () => {
            return
          }
        ) : (
          <div className="hands-img">
            <div className="hands">
              <img src={hands} alt="hands" />
            </div>
          </div>
        )}
        <div className="center-session">
          <h1 data-aos="fade-up">Impactamos e fomos impactados:</h1>
          <div
            className="enterprises"
            data-aos="fade-up-enterprises"
            data-aos-delay="200"
          >
            <div className="enterprise">
              <img src={alpargatas} id="alpargatas" alt="Alpargatas" />
            </div>
            <div className="enterprise">
              <img src={vitrium} id="vitrium" alt="Vitrium" />
            </div>
            <div className="enterprise">
              <img src={grupoNeves} id="grupoNeves" alt="Grupo Neves" />
            </div>
            <div className="enterprise">
              <img src={gerdau} id="gerdau" alt="Gerdau" />
            </div>
            <div className="enterprise">
              <img src={digna} id="digna" alt="Digna" />
            </div>
            <div className="enterprise">
              <img src={elfa} id="elfa" alt="Elfa" />
            </div>
            <div className="enterprise">
              <img src={leroy_merlin} id="leroy_merlin" alt="Leroy Merlin" />
            </div>
            <div className="enterprise">
              <img src={armazemParaiba} id="armazem-pb" alt="Armazém Paraíba" />
            </div>
            <div className="enterprise">
              <img src={carrefour} id="carrefour" alt="Carrefour" />
            </div>
            <div className="enterprise">
              <img src={guaraves} id="guaraves" alt="Guaraves" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Companies
